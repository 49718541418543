import { useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getArticles } from '../../axios/API';
import { classNames } from '../../utils/classNames';
import { useAppContext } from '../../context/AppContextProvider';

import MobileNavigation from '../navigation/MobileNavigation';
import SectionContainer from '../SectionContainer';

const ArticlesLayout = () => {
	const {
		mobileNavigationOpen,
		mobileNavigationFixed,
		scrollStartRef,
		sectionRefs,
		availableSectionsList,
		setAvailableSectionsList,
		contentList,
		setContentList,
		formLoading,
		questionLoading,
		sectionLoading,
		recordingLoading,
	} = useAppContext();

	const fetchArticles = async ({ pageParam = 0 }) => {
		try {
			const query = availableSectionsList[0];
			if (!query) return;
			const capitalizedQuery = query.charAt(0).toUpperCase() + query.slice(1);

			const { data } = await getArticles(query);
			setAvailableSectionsList((prev) => prev.filter((element) => element !== query));

			return {
				type: 'section',
				sectionRef: sectionRefs.find(({ section }) => section === query).ref,
				sectionName: capitalizedQuery,
				sectionId: query,

				items: data.block.items,
				questions: data.questions,

				nextPage: pageParam + 1,
				hasMore: availableSectionsList.length > 1,
			};
		} catch (error) {
			console.error(error);

			return {
				type: 'section',
				items: [],
				questions: [],

				nextPage: pageParam,
				hasMore: false,
			};
		}
	};

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
		queryKey: ['mainArticles'],
		queryFn: fetchArticles,
		refetchOnWindowFocus: false,

		getNextPageParam: (lastPage) => (lastPage?.hasMore ? lastPage?.nextPage : undefined),
	});

	useEffect(() => {
		if (data) {
			const newPages = data.pages.flatMap((page) => page);

			setContentList((prevList) => {
				const existingPages = new Set(prevList.map((item) => item?.nextPage));
				const filteredPages = newPages.filter((page) => !existingPages.has(page?.nextPage));

				return [...prevList, ...filteredPages];
			});
		}
		// eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		const scrollHandler = (event) => {
			const target = event.target;

			if (
				!formLoading &&
				!questionLoading &&
				hasNextPage &&
				!isFetchingNextPage &&
				!recordingLoading &&
				target.documentElement.scrollHeight - (target.documentElement.scrollTop + window.innerHeight) < 50
			) {
				fetchNextPage();
			}
		};

		document.addEventListener('scroll', scrollHandler);
		return () => document.removeEventListener('scroll', scrollHandler);
	}, [hasNextPage, isFetchingNextPage, fetchNextPage, formLoading, questionLoading, recordingLoading]);

	return (
		<div className='w-full'>
			<div className='w-full relative'>
				<div className='w-full flex flex-col'>
					<div
						ref={scrollStartRef}
						className={classNames(
							'w-full',
							mobileNavigationFixed ? (mobileNavigationOpen ? 'h-[465px]' : 'h-[80px]') : 'h-4'
						)}
					/>

					<MobileNavigation />
				</div>

				<div>
					{!!contentList.length &&
						contentList.map((page, pageIndex) => {
							const isLastPage = pageIndex === contentList.length - 1;
							const isFirstPage = pageIndex === 0;

							return (
								<SectionContainer
									key={pageIndex}
									page={page}
									pageIndex={pageIndex}
									isFetchingNextPage={isFetchingNextPage}
									isLastPage={isLastPage}
									isFirstPage={isFirstPage}
								/>
							);
						})}
				</div>

				<div className='h-[130px] md:h-[150px] flex justify-center mt-4'>
					{(formLoading ||
						questionLoading ||
						isFetchingNextPage ||
						recordingLoading ||
						(sectionLoading && availableSectionsList.length > 0)) && <span>Loading...</span>}
				</div>
			</div>
		</div>
	);
};

export default ArticlesLayout;
