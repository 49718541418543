import { classNames } from '../../utils/classNames';
import { removeMarkdown } from '../../utils/removeMarkdown';

import Markdown from './Markdown';
import ExpandButton from './ExpandButton';
import { partnersImages } from '../../constants/partners';

const MobileTemplateCard = ({
	isFirst,
	isExpanded,
	isCardPopupOpen,
	sectionName,
	contactContent,
	isContactSection,
	isProjectsSection,
	isPartnersSection,
	image,
	header,
	isTruncated,
	displayedText,
	toggleCard,
	handleOpenCardPopup,
}) => {
	const renderHeading = (header) => {
		const words = removeMarkdown(header).split(' ');

		return words.length > 1 && !isPartnersSection ? (
			<>
				<span className='block'>{words[0]}</span>
				<span className='w-full overflow-hidden break-words whitespace-normal'>{words.slice(1).join(' ')}</span>
			</>
		) : (
			<span className='w-full overflow-hidden break-words whitespace-normal'>{header}</span>
		);
	};

	return (
		<div
			onClick={() => toggleCard(header)}
			className={classNames(
				'flex flex-col gap-[22px]',
				'rounded-[26px] p-[18px]',
				'shadow-cardShadow bg-backgroundColor-secondary'
			)}
		>
			<div className='flex items-center gap-[22px]'>
				<img
					src={isPartnersSection ? partnersImages.find((item) => image.includes(item.value)).imageSource : image}
					alt=''
					className='w-full max-w-[50%] rounded-[11px] object-cover aspect-[140/90]'
				/>

				<h4
					className={classNames(
						'font-bold text-[18px] xs:text-[22px] leading-[24px] xs:leading-[28px]',
						'w-full overflow-hidden break-words whitespace-normal'
					)}
				>
					{renderHeading(isContactSection ? contactContent.header : header, sectionName)}
				</h4>
			</div>

			{isExpanded && (
				<>
					{isContactSection ? (
						<>
							<p className='font-medium text-sm leading-[25px] whitespace-normal'>{contactContent.beforeText}</p>

							<ul className='flex flex-col gap-2 my-3'>
								{contactContent.contacts.map(({ icon, link, name, text }, index) => (
									<li key={index}>
										<a
											href={link}
											target={name !== 'Phone' ? '_blank' : '_self'}
											rel='noopener noreferrer'
											className='text-accent flex items-center gap-2'
										>
											<img src={icon} alt={`${name} Icon`} className='w-[14px] h-[14px]' />

											<div className='flex items-center gap-1'>
												<span className='font-semibold text-sm leading-[25px]'>{name}:</span>
												<span>{text}</span>
											</div>
										</a>
									</li>
								))}
							</ul>

							<p className='font-medium text-sm'>{contactContent.afterText}</p>
						</>
					) : (
						<span className='font-medium text-sm leading-[25px] whitespace-normal'>
							<Markdown
								isFirst={isFirst}
								isExpanded={isExpanded}
								isCardPopupOpen={isCardPopupOpen}
								displayedText={displayedText}
							/>
						</span>
					)}
				</>
			)}

			{isTruncated && isExpanded && (
				<button
					className={classNames(
						'w-full h-[30px]',
						'text-xs text-input-placeholder font-bold',
						'ml-auto -mt-4 rounded-[30px] bg-[#E9F0FF]'
					)}
					onClick={() => handleOpenCardPopup(header)}
				>
					Read More
				</button>
			)}

			<div className='flex items-center justify-between'>
				{sectionName && (
					<div className='flex items-center gap-[6px]'>
						<div className='w-[17px] h-[17px] rounded-full bg-accentGradient' />
						<span className='text-[12px] leading-[16px]'>{sectionName}</span>
					</div>
				)}

				<ExpandButton sectionName={sectionName} isExpanded={isExpanded} />
			</div>
		</div>
	);
};

export default MobileTemplateCard;
