import ReactMarkdown from 'react-markdown';
import '../../styles/markdown.scss';

const Markdown = ({ isFirst, isExpanded, isCardPopupOpen, displayedText }) => {
	return (
		<ReactMarkdown
			className={isExpanded || isCardPopupOpen || isFirst ? '' : 'default-truncated-text'}
			components={{
				ol: ({ children }) => {
					return <ul className='flex flex-col gap-2 my-3 list-inside list-decimal'>{children}</ul>;
				},

				ul: ({ children }) => {
					return <ul className='flex flex-col gap-2 my-3 list-inside list-disc'>{children}</ul>;
				},

				li: ({ children }) => {
					return <li>{children}</li>;
				},

				p: ({ children }) => <p className='mb-4'>{children}</p>,
			}}
		>
			{displayedText}
		</ReactMarkdown>
	);
};

export default Markdown;
