import useMediaQuery from '../../hooks/useMediaQuery';
import { classNames } from '../../utils/classNames';

const AcceptAll = ({ acceptAllHandler, setSettings }) => {
  const isMobile = useMediaQuery('(max-width: 576px)');

  return (
    <div className={classNames('flex items-center gap-2', isMobile && 'flex-col')}>
      <div>
        <h3 className='text-small leading-[19px] font-bold text-black mb-2'>
          We Value Your Privacy
        </h3>
        <p
          className={classNames(
            'text-[11px] leading-[17px] font-medium text-black',
            isMobile && 'text-xs leading-[20px]'
          )}
        >
          We use cookies to enhance your browsing experience, analyze site traffic, and improve our
          services. By clicking "Accept All", you consent to our use of cookies. You can manage your
          preferences or disable certain types of cookies by clicking "Settings".
        </p>
      </div>
      <div
        className={classNames(
          'flex flex-col justify-between gap-2 h-full w-full',
          isMobile && 'flex-row mt-2'
        )}
      >
        <button
          type='button'
          onClick={acceptAllHandler}
          className={classNames(
            'w-[200px] xl:w-[257px] h-[31px] rounded-[32px] bg-[#85D1FF] text-xs text-[#1F6FA0] font-bold',
            isMobile && 'w-[65%]'
          )}
        >
          Accept All
        </button>

        <button
          type='button'
          onClick={() => setSettings(true)}
          className={classNames(
            'w-[200px] xl:w-[257px] h-[31px] rounded-[32px]  border border-[#85D1FF] bg-[#fff] text-xs text-[#1F6FA0] font-medium',
            isMobile && 'w-[35%] border-0'
          )}
        >
          Settings
        </button>
      </div>
    </div>
  );
};

export default AcceptAll;
