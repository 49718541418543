import { classNames } from '../../utils/classNames';
import chevronIcon from '../../assets/templates-cards/chevron-icon.svg';

const ExpandButton = ({ sectionName, isExpanded }) => {
	return (
		<button
			aria-label={isExpanded ? 'Collapse card' : 'Expand card'}
			className={classNames(!sectionName ? 'ml-auto' : '')}
		>
			<img
				src={chevronIcon}
				alt={isExpanded ? 'Collapse icon' : 'Expand icon'}
				className={classNames(isExpanded ? 'rotate-180' : '', 'h-[30px] w-[30px]')}
			/>
		</button>
	);
};

export default ExpandButton;
