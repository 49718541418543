import { createContext, useContext, useRef, createRef, useState, useEffect } from 'react';

import { NAVIGATION } from '../constants/navigation';

const AppContext = createContext(null);

export const useAppContext = () => useContext(AppContext);

const AppContextProvider = ({ children }) => {
	const scrollStartRef = useRef(null);

	const [availableSectionsList, setAvailableSectionsList] = useState(NAVIGATION.map(({ value }) => value));
	const [passedNavigationList, setPassedNavigationList] = useState([]);

	const [sectionRefs, setSectionRefs] = useState(
		NAVIGATION.map(({ value }) => ({
			section: value,
			ref: createRef(),
		}))
	);

	const [navigationPath, setNavigationPath] = useState('');

	const [isRequestPopupOpen, setIsRequestPopupOpen] = useState(false);
	const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false);
	const [mobileNavigationFixed, setMobileNavigationFixed] = useState(false);

	const [queryInput, setQueryInput] = useState('');
	const [contentList, setContentList] = useState([]);
	const [requestRecord, setRequestRecord] = useState([]);

	const [formLoading, setFormLoading] = useState(false);
	const [sectionLoading, setSectionLoading] = useState(false);
	const [questionLoading, setQuestionLoading] = useState(false);
	const [recordingLoading, setRecordingLoading] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			sectionRefs.forEach((section) => {
				if (section.ref.current) {
					const sectionId = section.ref.current.getAttribute('id');
					const sectionRect = section.ref.current.getBoundingClientRect();
					const sectionMiddle = sectionRect.top + sectionRect.height / 2;

					const isMiddleVisible = sectionMiddle >= 0 && sectionMiddle <= window.innerHeight;
					const isBottomVisible = sectionRect.bottom <= window.innerHeight;

					if (isMiddleVisible) {
						setNavigationPath(sectionId);
					}

					if (isBottomVisible) {
						setPassedNavigationList((prevList) => {
							if (!prevList.includes(sectionId)) {
								return [...prevList, sectionId];
							}

							return prevList;
						});
					}
				}
			});
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [sectionRefs]);

	const scrollToTop = async () => {
		await new Promise((resolve) => setTimeout(resolve, 0));

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const scrollToBottom = async () => {
		await new Promise((resolve) => setTimeout(resolve, 0));

		window.scrollTo({
			top: document.body.scrollHeight,
			behavior: 'smooth',
		});
	};

	const handleChangeSectionRefs = (section = '') => {
		const existingSectionRef = sectionRefs.find(({ section: existingSection }) => existingSection === section);

		if (existingSectionRef) {
			return existingSectionRef.ref;
		}

		const sectionRef = createRef();

		setSectionRefs((prevRefs) => [
			...prevRefs,
			{
				section,
				ref: sectionRef,
			},
		]);

		return sectionRef;
	};

	const addUserQueryMessage = (query) => {
		setContentList((prevList) => [
			...prevList,
			{
				items: [
					{
						text: query,
					},
				],

				type: 'user-message',
			},
		]);

		scrollToBottom();
	};

	useEffect(() => {
		const storedRequests = localStorage.getItem('genumRequestRecord');
		if (storedRequests) setRequestRecord(JSON.parse(storedRequests));
	}, []);

	const updateRequestRecord = (request) => {
		setRequestRecord((prev) => {
			const isRequestExists = prev.some((item) => item === request);

			if (isRequestExists) {
				return prev;
			}

			const updatedRequests = [...prev, request];
			localStorage.setItem('genumRequestRecord', JSON.stringify(updatedRequests));
			return updatedRequests;
		});
	};

	return (
		<AppContext.Provider
			value={{
				scrollStartRef,
				scrollToTop,
				scrollToBottom,

				mobileNavigationOpen,
				setMobileNavigationOpen,
				mobileNavigationFixed,
				setMobileNavigationFixed,
				isRequestPopupOpen,
				setIsRequestPopupOpen,

				sectionRefs,
				setSectionRefs,
				handleChangeSectionRefs,

				addUserQueryMessage,
				availableSectionsList,
				setAvailableSectionsList,
				passedNavigationList,
				setPassedNavigationList,

				queryInput,
				setQueryInput,
				contentList,
				setContentList,
				requestRecord,
				setRequestRecord,
				updateRequestRecord,

				navigationPath,
				setNavigationPath,

				formLoading,
				setFormLoading,
				sectionLoading,
				setSectionLoading,
				questionLoading,
				setQuestionLoading,
				recordingLoading,
				setRecordingLoading,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContextProvider;
