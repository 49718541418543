import { forwardRef, useState, Fragment } from 'react';

import useMediaQuery from '../../hooks/useMediaQuery';
import { contactContent } from '../../constants/contact';
import { truncateTextWithMarkdown } from '../../utils/truncateText';

import CardPopup from '../popup/CardPopup';
import MobileTemplateCard from './MobileTemplateCard';
import DesktopTemplateCard from './DesktopTemplateCard';

const TemplateCardWrapper = forwardRef(
	({ mode, image, header, text, isFirst, isLast, sectionName, setContentActiveItems }, ref) => {
		const [isExpanded, setIsExpanded] = useState(false);
		const [isCardPopupOpen, setIsCardPopupOpen] = useState(false);

		const isMobile = useMediaQuery('(max-width: 576px)');
		const isDesktop = useMediaQuery('(min-width: 1024px)');

		const handleHighlighContentItem = (header) => {
			setContentActiveItems((prev) => {
				if (!prev.includes(header)) return [...prev, header];

				return prev;
			});
		};

		const toggleCard = (header) => {
			setIsExpanded((prev) => !prev);
			handleHighlighContentItem(header);
		};

		const isPartnersSection = sectionName === 'Partners';
		const isProjectsSection = sectionName === 'Projects';
		const isContactSection = sectionName === 'Contacts' || sectionName === 'Contact' || header.includes('Contact');

		const maxLength = isExpanded && !isDesktop ? 1000 : 700;

		const { truncatedText, isTruncated } = truncateTextWithMarkdown(text, maxLength);

		const handleOpenCardPopup = (header) => {
			setIsCardPopupOpen(true);
			handleHighlighContentItem(header);
		};

		return (
			<Fragment>
				{isMobile && !isFirst ? (
					<MobileTemplateCard
						isFirst={isFirst}
						isExpanded={isExpanded}
						isCardPopupOpen={isCardPopupOpen}
						sectionName={sectionName}
						contactContent={contactContent}
						isContactSection={isContactSection}
						isProjectsSection={isProjectsSection}
						isPartnersSection={isPartnersSection}
						image={image}
						header={header}
						isTruncated={isTruncated}
						displayedText={truncatedText}
						toggleCard={toggleCard}
						handleOpenCardPopup={handleOpenCardPopup}
					/>
				) : (
					<DesktopTemplateCard
						ref={ref}
						isLast={isLast}
						isFirst={isFirst}
						isExpanded={isExpanded}
						sectionName={sectionName}
						contactContent={contactContent}
						isContactSection={isContactSection}
						isProjectsSection={isProjectsSection}
						isCardPopupOpen={isCardPopupOpen}
						image={image}
						header={header}
						text={text}
						isTruncated={isTruncated}
						displayedText={truncatedText}
						toggleCard={toggleCard}
						handleOpenCardPopup={handleOpenCardPopup}
						isDesktop={isDesktop}
					/>
				)}

				<CardPopup
					isOpen={isCardPopupOpen}
					isFirst={isFirst}
					isExpanded={isExpanded}
					isCardPopupOpen={isCardPopupOpen}
					isProjectsSection={isProjectsSection}
					isContactSection={isContactSection}
					contactContent={contactContent}
					handleClose={() => setIsCardPopupOpen(false)}
					cardContent={{ image, header, text }}
					zIndex='z-[80]'
					className={[
						'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
						'max-w-[849px] w-full px-2 md:px-3 lg:px-4 z-[81]',
					]}
				/>
			</Fragment>
		);
	}
);

export default TemplateCardWrapper;
