const CheckBox = ({ label, id, checked, onChange }) => {
  return (
    <div className='w-full flex items-center gap-2'>
      <input
        id={id}
        type='checkbox'
        checked={checked}
        onChange={onChange}
        className='peer relative appearance-none shrink-0 size-[12px] border-1 border-[#85D1FF] rounded-sm bg-white
                        focus:outline-none checked:bg-[#85D1FF] checked:border-0
                        disabled:border-steel-400 disabled:bg-steel-400'
      />
      <svg
        className='absolute size-[12px] pointer-events-none hidden peer-checked:block stroke-white outline-none'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <polyline points='20 6 9 17 4 12'></polyline>
      </svg>
      <label htmlFor={id} className='text-xs leading-[20px] font-bold text-black'>
        {label}
      </label>
    </div>
  );
};

export default CheckBox;
