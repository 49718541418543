import { useState } from 'react';
import CheckBox from '../CheckBox';
import { classNames } from '../../utils/classNames';
import useMediaQuery from '../../hooks/useMediaQuery';

const CookieSettings = ({ savePreferencesHandler }) => {
  const [checboxesValue, setChecboxesValue] = useState([
    { name: 'analytics-cookies', value: false },
    { name: 'functional-cookies', value: false },
    { name: 'marketing-cookies', value: false },
  ]);

  const isMobile = useMediaQuery('(max-width: 576px)');
  const isSmallHeight = useMediaQuery('(max-height: 740px)');

  const checkBoxHandler = number => {
    setChecboxesValue(state => {
      const newState = [...state];
      newState[number].value = !state[number].value;
      return newState;
    });
  };

  const acceptAllHandler = () => {
    setChecboxesValue([
      { name: 'analytics-cookies', value: true },
      { name: 'functional-cookies', value: true },
      { name: 'marketing-cookies', value: true },
    ]);

    savePreferencesHandler([
      { name: 'analytics-cookies', value: true },
      { name: 'functional-cookies', value: true },
      { name: 'marketing-cookies', value: true },
    ]);
  };

  const declineAllHandler = () => {
    setChecboxesValue([
      { name: 'analytics-cookies', value: false },
      { name: 'functional-cookies', value: false },
      { name: 'marketing-cookies', value: false },
    ]);

    savePreferencesHandler([
      { name: 'analytics-cookies', value: false },
      { name: 'functional-cookies', value: false },
      { name: 'marketing-cookies', value: false },
    ]);
  };

  return (
    <div className='flex flex-col gap-2'>
      <div
        className={classNames(
          'flex flex-col gap-2 scrollbar-custom',
          !isMobile && isSmallHeight && 'overflow-auto max-h-[140px] pr-1'
        )}
      >
        <div>
          <h4
            className={classNames(
              'text-xs leading-[20px] font-bold text-black mb-3',
              isMobile && 'text-sm '
            )}
          >
            Cookies Preferences
          </h4>
          <p
            className={classNames(
              'text-[11px] leading-[14px] font-medium text-black',
              isMobile && 'text-xs leading-[20px]'
            )}
          >
            We use cookies to optimize your experience. You can manage your preferences below. For
            more details, review our{' '}
            <span className='text-[#85D1FF] cursor-pointer'>Privacy Policy</span>.
          </p>
        </div>

        <div>
          <h4 className='text-xs leading-[20px] font-bold text-black mb-1'>
            Essential Cookies (Required)
          </h4>
          <p
            className={classNames(
              'text-[11px] leading-[14px] font-medium text-black',
              isMobile && 'text-xs leading-[20px]'
            )}
          >
            These cookies are necessary for the website to function and cannot be disabled. They
            help with security, accessibility, and basic site navigation.
          </p>
        </div>
        <div>
          <CheckBox
            label='Analytics & Performance Cookies'
            id='performanceCookies'
            checked={checboxesValue[0].value}
            onChange={() => checkBoxHandler(0)}
          />
          <p
            className={classNames(
              'ms-5 text-[11px] leading-[14px] font-medium text-black mt-1',
              isMobile && 'text-xs leading-[20px]'
            )}
          >
            These cookies help us understand how visitors interact with our site by collecting
            anonymized data. They allow us to improve functionality and optimize performance.
          </p>
        </div>
        <div>
          <CheckBox
            label='Functional Cookies'
            id='functionalCookies'
            checked={checboxesValue[1].value}
            onChange={() => checkBoxHandler(1)}
          />
          <p
            className={classNames(
              'ms-5 text-[11px] leading-[14px] font-medium text-black mt-1',
              isMobile && 'text-xs leading-[20px]'
            )}
          >
            These cookies help us understand how visitors interact with our site by collecting
            anonymized data. They allow us to improve functionality and optimize performance.
          </p>
        </div>
        <div>
          <CheckBox
            label='Marketing & Advertising Cookies'
            id='marketingCookies'
            checked={checboxesValue[2].value}
            onChange={() => checkBoxHandler(2)}
          />
          <p
            className={classNames(
              'ms-5 text-[11px] leading-[14px] font-medium text-black mt-1',
              isMobile && 'text-xs leading-[20px]'
            )}
          >
            These cookies allow us to provide personalized ads and track interactions with
            third-party services.
          </p>
        </div>
      </div>
      <div className='flex flex-col justify-between gap-3 mt-1'>
        <button
          type='button'
          onClick={acceptAllHandler}
          className={'w-full h-[31px] rounded-[32px] bg-[#85D1FF] text-xs text-[#1F6FA0] font-bold'}
        >
          Accept All
        </button>
        <div className='flex justify-between gap-3'>
          <button
            type='button'
            onClick={declineAllHandler}
            className={classNames(
              'w-[50%] h-[31px] rounded-[32px]  border border-[#85D1FF] bg-[#fff] text-xs text-[#1F6FA0] font-medium',
              isMobile && ' border-0'
            )}
          >
            Decline All
          </button>
          <button
            type='button'
            onClick={() => savePreferencesHandler(checboxesValue)}
            className={classNames(
              'w-[50%] h-[31px] rounded-[32px]  border border-[#85D1FF] bg-[#fff] text-xs text-[#1F6FA0] font-medium',
              isMobile && ' border-0'
            )}
          >
            Save Preferences
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieSettings;
