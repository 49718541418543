import { useRef, createRef, useState } from 'react';
import { classNames } from '../../utils/classNames';
import { removeMarkdown } from '../../utils/removeMarkdown';

import TemplateCardWrapper from './TemplateCardWrapper';
import MasonryLayout from './MasonryLayout';
import RelatedQuestions from '../questions/RelatedQuestions';
import Contents from '../navigation/Contents';

const ResponseWrapper = ({ page, pageIndex, sectionLoading }) => {
	const [activeContentItems, setContentActiveItems] = useState([]);

	const validateArray = (array) => Array.isArray(array) && array.length > 0;

	const validateEntity = (entities) => {
		return Array.isArray(entities)
			? entities.every((entity) => typeof entity === 'object' && entity !== null)
			: !!entities && typeof entities === 'object';
	};

	const contentList = validateArray(page.items)
		? page.items.map(({ header }, index) => ({
				id: index + 1,
				content: removeMarkdown(header),
		  }))
		: [];

	const cardRefs = useRef(contentList.map(() => createRef()));

	const contentWithRefs = contentList.map((item, index) => ({
		...item,
		ref: cardRefs.current[index],
	}));

	const combinedItem =
		page.type === 'form-response' || page.type === 'questions'
			? {
					header: page.items[0]?.header || 'Invalid Request!',
					image: page.items[0]?.img || '',
					text: page.items.map((item) => item.text).join('\n\n'),
			  }
			: {
					header: page.items[0].header,
					image: page.items[0].img,
					text: page.items[0].text,
			  };

	return (
		<div className={classNames('relative m-auto  w-full')}>
			<div
				className={classNames(
					'hidden lg:flex h-[calc(100%-48px)]',
					'absolute right-14 xl:right-4 xxl:right-2',
					'max-w-[150px] xl:max-w-[200px] xxl:max-w-[250px] w-full'
				)}
			>
				{validateArray(page.items) && (
					<Contents
						pageType={page.type}
						pageIndex={pageIndex}
						contentList={contentWithRefs}
						activeContentItems={activeContentItems}
					/>
				)}
			</div>

			<div
				className={classNames(
					'relative m-auto',
					'max-w-[820px] w-full',
					'lg:max-w-[clamp(550px,55%,820px)]',
					'xl:max-w-[clamp(600px,60%,820px)]',
					'xxl:max-w-[clamp(600px,70%,820px)]'
				)}
			>
				{page.sectionName !== null && page.sectionName !== undefined && (
					<h3 className='text-[26px] leading-[36px] md:text-[32px] md:leading-[44px] font-bold text-input-placeholder ml-[18px] mb-6 md:mb-8'>
						{page.sectionName === 'Technologies' ? 'Tech' : page.sectionName}
					</h3>
				)}

				{validateEntity(combinedItem) && (
					<TemplateCardWrapper
						ref={cardRefs.current[0]}
						image={combinedItem.image}
						header={combinedItem.header}
						text={combinedItem.text}
						maxWidth='max-w-[820px]'
						isFirst={true}
						setContentActiveItems={setContentActiveItems}
						sectionName={page.sectionName}
					/>
				)}

				{page.type !== 'form-response' && page.type !== 'questions' && (
					<MasonryLayout
						page={page}
						cardRefs={contentWithRefs}
						validateArray={validateArray}
						validateEntity={validateEntity}
						setContentActiveItems={setContentActiveItems}
					/>
				)}

				{validateArray(page.questions) && (
					<RelatedQuestions questions={page.questions} sectionLoading={sectionLoading} />
				)}
			</div>
		</div>
	);
};

export default ResponseWrapper;
