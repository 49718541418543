import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

const GAConnector = () => {
  const [cookies] = useCookies(['analytics-cookies']);

  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_MEASUREMENT_ID}`;

    script.onload = () => {
      if (cookies['analytics-cookies'] !== 'allow') {
        gtag('consent', 'default', {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'denied',
        });

        gtag('consent', 'update', {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'denied',
        });
      }

      if (cookies['analytics-cookies'] === 'allow') {
        gtag('consent', 'update', {
          ad_storage: 'granted',
          ad_user_data: 'granted',
          ad_personalization: 'granted',
          analytics_storage: 'granted',
        });
      }

      gtag('js', new Date());
      gtag('config', process.env.REACT_APP_MEASUREMENT_ID);
    };

    document.head.appendChild(script);
  }, [cookies]);

  return null;
};

export default GAConnector;
