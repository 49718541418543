import { useRef, useState } from 'react';
import { classNames } from '../../utils/classNames';

const Contents = ({ pageType, contentList, activeContentItems }) => {
	const [activeContentItem, setActiveContentItem] = useState(null);
	const contentRef = useRef();

	const handleScrollToCard = (content, ref) => {
		setActiveContentItem(content);

		if (ref?.current) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
	};

	const isValidPageType = pageType === 'form-response' || pageType === 'questions';
	const itemsToDisplay = isValidPageType ? contentList.slice(0, 1) : contentList;

	return (
		<div className={classNames('relative w-full h-full mt-10', isValidPageType ? '-mt-8' : 'mt-10')}>
			<div className='sticky top-0 left-0 pt-8 pb-[200px]'>
				<div className='absolute' ref={contentRef}>
					<div
						className={classNames(
							'hidden lg:flex flex-col gap-5',
							'max-w-[150px] xl:max-w-[200px] xxl:max-w-[250px] w-full'
						)}
					>
						<span className='leading-[22px] font-bold'>Contents</span>

						<ul className='w-full flex flex-col gap-[10px]'>
							{itemsToDisplay.map(({ id, content, ref }) => {
								return (
									<li
										key={id}
										onClick={() => handleScrollToCard(content, ref)}
										className={classNames(
											'flex items-center w-full',
											'py-1 overflow-hidden',
											'transition-all duration-200 ease-soft-spring',
											'hover:pl-3 hover:border-l-2 hover:border-blue-500',
											activeContentItems.includes(content) || activeContentItem === content
												? 'pl-3 border-l-2 border-blue-500'
												: 'pl-2 border-l-1 border-black/60'
										)}
									>
										<span
											className={classNames(
												'block overflow-hidden whitespace-nowrap',
												'transition-colors duration-200 ease-in-out cursor-pointer',
												'font-semibold text-[13px] leading-[18px] hover:text-blue-500',
												activeContentItems.includes(content) || activeContentItem === content
													? 'text-blue-500'
													: 'text-black/60'
											)}
											style={{
												textOverflow: 'ellipsis',
												maxWidth: '100%',
												position: 'relative',
											}}
										>
											{content}

											<span
												className='absolute right-0 text-blue-500'
												style={{
													content: "'...'",
													backgroundColor: 'inherit',
													position: 'absolute',
													right: 0,
													color: 'currentColor',
												}}
											></span>
										</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Contents;
