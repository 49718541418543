import { ReactComponent as HomeIcon } from '../assets/navigation/home.svg';
import { ReactComponent as ActiveHomeIcon } from '../assets/navigation/active-home.svg';

import { ReactComponent as SolutionsIcon } from '../assets/navigation/solutions.svg';
import { ReactComponent as ActiveSolutionsIcon } from '../assets/navigation/active-solutions.svg';

import { ReactComponent as TechnologiesIcon } from '../assets/navigation/tech.svg';
import { ReactComponent as ActiveTechnologiesIcon } from '../assets/navigation/active-tech.svg';

import { ReactComponent as ProjectsIcon } from '../assets/navigation/projects.svg';
import { ReactComponent as ActiveProjectsIcon } from '../assets/navigation/active-projects.svg';

import { ReactComponent as PartnersIcon } from '../assets/navigation/partners.svg';
import { ReactComponent as ActivePartnersIcon } from '../assets/navigation/active-partners.svg';

import { ReactComponent as ContactIcon } from '../assets/navigation/contact.svg';
import { ReactComponent as ActiveContactIcon } from '../assets/navigation/active-contact.svg';

import { ReactComponent as VPIcon } from '../assets/navigation/vp.svg';
import { ReactComponent as ActiveVPIcon } from '../assets/navigation/active-vp.svg';

import { ReactComponent as AboutIcon } from '../assets/navigation/about.svg';
import { ReactComponent as ActiveAboutIcon } from '../assets/navigation/active-about.svg';

export const NAVIGATION = [
	{
		label: 'Home',
		value: 'home',
		IconComponent: HomeIcon,
		ActiveIconComponent: ActiveHomeIcon,
	},

	{
		label: 'Solutions',
		value: 'solutions',
		IconComponent: SolutionsIcon,
		ActiveIconComponent: ActiveSolutionsIcon,
	},

	{
		label: 'Tech',
		value: 'technologies',
		IconComponent: TechnologiesIcon,
		ActiveIconComponent: ActiveTechnologiesIcon,
	},

	{
		label: 'Projects',
		value: 'projects',
		IconComponent: ProjectsIcon,
		ActiveIconComponent: ActiveProjectsIcon,
	},

	{
		label: 'VP',
		value: 'value proposition',
		IconComponent: VPIcon,
		ActiveIconComponent: ActiveVPIcon,
	},

	{
		label: 'Partners',
		value: 'partners',
		IconComponent: PartnersIcon,
		ActiveIconComponent: ActivePartnersIcon,
	},

	{
		label: 'About',
		value: 'about',
		IconComponent: AboutIcon,
		ActiveIconComponent: ActiveAboutIcon,
	},

	{
		label: 'Contacts',
		value: 'contact',
		IconComponent: ContactIcon,
		ActiveIconComponent: ActiveContactIcon,
	},
];
