import { useMemo, useState } from 'react';
import { classNames } from '../../utils/classNames';
import AcceptAll from './AcceptAll';
import CookieSettings from './CookieSettings';
import { useCookies } from 'react-cookie';
import useMediaQuery from '../../hooks/useMediaQuery';

const CookiesAccept = ({ inView }) => {
  const [visible, setVisible] = useState(true);
  const [settings, setSettings] = useState(false);
  const [cookies, setCookie] = useCookies();

  const isMobile = useMediaQuery('(max-width: 576px)');

  const acceptAllHandler = () => {
    setVisible(false);

    setCookie('analytics-cookies', 'allow');
    setCookie('functional-cookies', 'allow');
    setCookie('marketing-cookies', 'allow');
  };

  const acceptCustomHandler = checkboxesValues => {
    setVisible(false);

    checkboxesValues.forEach((item, index) => {
      if (!!item.value) {
        setCookie(item.name, 'allow');
      } else {
        setCookie(item.name, 'decline');
      }
    });
  };

  const isShowCookieNotif = useMemo(() => {
    if (
      cookies['analytics-cookies'] ||
      cookies['functional-cookies'] ||
      cookies['marketing-cookies']
    ) {
      return false;
    } else return true;
  }, [cookies]);

  return (
    isShowCookieNotif &&
    visible && (
      <div
        className={classNames(
          'fixed bottom-8 z-[45] flex flex-col items-center w-full px-2 md:px-3 xl:px-4 ',
          `${
            !inView &&
            !(isMobile && settings) &&
            'translate-y-[-110px] md:translate-y-[-130px] lg:translate-y-[-100px] transition-transform'
          }`,
          isMobile && settings && 'top-[0px] bottom-[0px] bg-[#0000008c] justify-center'
        )}
      >
        <div
          className={classNames(
            'px-[18px] max-w-[820px] lg:max-w-[clamp(550px,55%,820px)] xl:max-w-[clamp(600px,60%,820px)] xxl:max-w-[clamp(600px,70%,820px)]'
          )}
        >
          <div className='rounded-[26px] bg-backgroundColor-secondary p-[26px] flex items-center gap-2 border border-[#85d0ff4c] shadow-[18px_-6px_33px_5px_#00000008]'>
            {!settings ? (
              <AcceptAll acceptAllHandler={acceptAllHandler} setSettings={setSettings} />
            ) : (
              <CookieSettings savePreferencesHandler={acceptCustomHandler} />
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default CookiesAccept;
