import { Fragment } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import TemplateCardWrapper from './TemplateCardWrapper';

const MasonryLayout = ({ page, cardRefs, validateArray, validateEntity, setContentActiveItems }) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	if (!validateArray(page.items) || !validateEntity(page.items)) return null;

	const evenItems = page.items.slice(1).filter((_, index) => index % 2 === 0);
	const oddItems = page.items.slice(1).filter((_, index) => index % 2 !== 0);

	return (
		<div className='grid grid-cols-1 md:grid-cols-2 gap-[22px] md:gap-6'>
			{isMobile ? (
				page.items.slice(1).map(({ img: image, header, text }, index) => {
					const isLast = index === page.items.slice(1).length - 1;

					return (
						<TemplateCardWrapper
							key={`${header}-${index}`}
							ref={cardRefs.find((card) => card.content === header)?.ref}
							image={image}
							header={header}
							text={text}
							isFirst={false}
							isLast={isLast}
							sectionName={page.sectionName}
							setContentActiveItems={setContentActiveItems}
						/>
					);
				})
			) : (
				<Fragment>
					{/* LEFT COLUMN */}
					<div className='hidden md:flex flex-col gap-[22px] md:gap-6 flex-1'>
						{evenItems.map(({ img: image, header, text }, index) => {
							const isLast = index === evenItems.length - 1;

							return (
								<TemplateCardWrapper
									mode='even'
									key={`${header}-even-${index}`}
									ref={cardRefs.find((card) => card.content === header)?.ref}
									image={image}
									header={header}
									text={text}
									isFirst={false}
									isLast={isLast}
									sectionName={page.sectionName}
									setContentActiveItems={setContentActiveItems}
								/>
							);
						})}
					</div>

					{/* RIGHT COLUMN */}
					<div className='hidden md:flex flex-col gap-[22px] md:gap-6 flex-1'>
						{oddItems.map(({ img: image, header, text }, index) => {
							const isLast = index === oddItems.length - 1;

							return (
								<TemplateCardWrapper
									mode='odd'
									key={`${header}-odd-${index}`}
									ref={cardRefs.find((card) => card.content === header)?.ref}
									image={image}
									header={header}
									text={text}
									isFirst={false}
									isLast={isLast}
									sectionName={page.sectionName}
									setContentActiveItems={setContentActiveItems}
								/>
							);
						})}
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default MasonryLayout;
