import { useState } from 'react';
import { getArticles } from '../../axios/API';
import { classNames } from '../../utils/classNames';
import { NAVIGATION } from '../../constants/navigation';
import { useAppContext } from '../../context/AppContextProvider';

const Navigation = ({ mode, className }) => {
	const [hoveredItem, setHoveredItem] = useState(null);

	const {
		sectionRefs,
		setSectionLoading,
		setMobileNavigationOpen,
		contentList,
		setContentList,
		setNavigationPath,
		setAvailableSectionsList,
		passedNavigationList,
	} = useAppContext();

	const moveSection = (sectionId, contentList) => {
		const existingSectionIndex = contentList.findIndex((item) => item.sectionId === sectionId);

		if (existingSectionIndex === -1) return null;

		const updatedContentList = [...contentList];
		const [existingSection] = updatedContentList.splice(existingSectionIndex, 1);

		updatedContentList.push(existingSection);

		return {
			updatedContentList,
			movedSectionRef: existingSection.sectionRef,
		};
	};

	const fetchAndAddSection = async (sectionId, contentList) => {
		const { data } = await getArticles(sectionId);

		const fetchedSection = {
			type: 'section',
			sectionRef: sectionRefs.find(({ section }) => section === sectionId).ref,
			sectionName: sectionId.charAt(0).toUpperCase() + sectionId.slice(1),
			sectionId: sectionId,
			items: data.block.items,
			questions: data.questions,
		};

		const updatedContentList = [...contentList];
		updatedContentList.push(fetchedSection);

		return {
			fetchedSection,
			updatedContentList,
			newSectionRef: fetchedSection.sectionRef,
		};
	};

	const scrollToSection = (sectionRef) => {
		setTimeout(() => {
			requestAnimationFrame(() => {
				if (sectionRef?.current) {
					sectionRef.current.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				}
			});
		}, 100);
	};

	const handleNavigationClick = async (value) => {
		setSectionLoading(true);

		try {
			const existingSectionResult = moveSection(value, contentList);

			if (existingSectionResult) {
				setContentList(existingSectionResult.updatedContentList);
				setNavigationPath(value);
				scrollToSection(existingSectionResult.movedSectionRef);
				setSectionLoading(false);

				return;
			}

			const { updatedContentList, newSectionRef } = await fetchAndAddSection(value, contentList);

			setContentList(updatedContentList);
			setAvailableSectionsList((prev) => prev.filter((element) => element !== value));

			setNavigationPath(value);
			scrollToSection(newSectionRef);
		} catch (error) {
			console.error(error);
		} finally {
			setSectionLoading(false);
			mode === 'mobile' && setMobileNavigationOpen(false);
		}
	};

	// VERSION WITH FETCHING SECTION

	// const handleNavigationClick = async (value) => {
	// 	setSectionLoading(true);

	// 	try {
	// 		const existingSectionResult = moveSection(value, contentList);

	// 		if (existingSectionResult) {
	// 			setContentList(existingSectionResult.updatedContentList);
	// 			scrollToSection(existingSectionResult.movedSectionRef);

	// 			const { fetchedSection } = await fetchAndAddSection(value, contentList);

	// 			await new Promise((resolve) => setTimeout(() => resolve), 0);
	// 			setContentList((prev) => {
	// 				const newState = [...prev];
	// 				const sectionIndex = prev.findIndex(({ sectionId }) => sectionId === value);
	// 				newState[sectionIndex] = fetchedSection;

	// 				return newState;
	// 			});
	// 			setSectionLoading(false);

	// 			return;
	// 		}

	// 		const { updatedContentList, newSectionRef } = await fetchAndAddSection(value, contentList);

	// 		setContentList(updatedContentList);
	// 		setAvailableSectionsList((prev) => prev.filter((element) => element !== value));
	// 		scrollToSection(newSectionRef);
	// 	} catch (error) {
	// 		console.error(error);
	// 	} finally {
	// 		setSectionLoading(false);
	// 		mode === 'mobile' && setMobileNavigationOpen(false);
	// 	}
	// };

	return (
		<ul className={classNames(className)}>
			{NAVIGATION.map(({ label, value, IconComponent, ActiveIconComponent }) => {
				const isAvailable = Array.isArray(passedNavigationList) && passedNavigationList.includes(value);

				return (
					<li
						key={label}
						onClick={() => handleNavigationClick(value)}
						onMouseEnter={() => setHoveredItem(value)}
						onMouseLeave={() => setHoveredItem(null)}
						onTouchStart={() => setHoveredItem(value)}
						onTouchEnd={() => setHoveredItem(null)}
						className={classNames(
							'max-w-[71px] w-full flex flex-col gap-[10px] items-center justify-between',
							'group transition-all duration-300 ease-in-out cursor-pointer'
						)}
					>
						<div className='relative w-[47px] h-[47px]'>
							<div
								className={classNames(
									'absolute inset-0 transition-opacity duration-300 ease-in-out',
									hoveredItem === value || isAvailable ? 'opacity-100' : 'opacity-0'
								)}
							>
								{ActiveIconComponent && <ActiveIconComponent />}
							</div>

							<div
								className={classNames(
									'absolute inset-0 transition-opacity duration-300 ease-in-out',
									hoveredItem !== value && !isAvailable ? 'opacity-100' : 'opacity-0'
								)}
							>
								<IconComponent />
							</div>
						</div>
						<span className='font-semibold text-[11px] leading-[15px]'>{label}</span>
					</li>
				);
			})}
		</ul>
	);
};

export default Navigation;
