import logoIcon from '../assets/logo.svg';

import { classNames } from '../utils/classNames';
import { useAppContext } from '../context/AppContextProvider';

const MobileLogo = ({ logoRef, className }) => {
	const { scrollToTop } = useAppContext();

	return (
		<div ref={logoRef} onClick={scrollToTop} className={classNames('z-20 select-none', className)}>
			<img src={logoIcon} alt='GENUM LOGO' className='w-[94px] h-[18px]' />
		</div>
	);
};

export default MobileLogo;
