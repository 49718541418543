import { Fragment } from 'react';
import { useInView } from 'react-intersection-observer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import AppContextProvider from './context/AppContextProvider';

import GAConnector from './components/GAConnector';
import FloatingSidebar from './components/navigation/FloatingSidebar';
import HomeSection from './sections/HomeSection';
import ArticlesLayout from './components/template-cards/ArticlesLayout';
import FormComponent from './components/form/FormComponent';
import CookiesAccept from './components/cookies-accept/CookiesAccept';

const queryClient = new QueryClient();

const App = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  return (
    <Fragment>
      {process.env.REACT_APP_DEVELOPMENT_MODE === 'production' && <GAConnector />}

      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <main className='relative min-h-screen h-full max-w-screen-xxl w-full flex flex-col items-center m-auto px-2 md:px-3 xl:px-4'>
            <FloatingSidebar />
            <HomeSection ref={ref} />
            <ArticlesLayout />

            {!inView && (
              <div className='flex flex-col w-full'>
                <FormComponent mode='bottom' className='fixed bottom-8 left-0 px-4 md:px-0 z-40' />
                <div className='fixed bottom-0 left-0 h-[102px] w-full bg-whiteGradient z-10' />
              </div>
            )}

            <CookiesAccept inView={inView} />
          </main>
        </AppContextProvider>
      </QueryClientProvider>
    </Fragment>
  );
};

export default App;
