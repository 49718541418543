import { classNames } from '../utils/classNames';

import ResponseWrapper from './template-cards/ResponseWrapper';

const SectionContainer = ({ page, pageIndex, isFirstPage, isFetchingNextPage }) => {
  return (
    <section key={pageIndex} id={page.sectionId} ref={page.sectionRef} className='relative'>
      {!isFirstPage && <div className='h-20 lg:h-4 w-full' />}

      <div className={classNames('relative flex justify-center w-full lg:pb-12')}>
        {page.type === 'section' && (
          <ResponseWrapper page={page} sectionLoading={isFetchingNextPage} />
        )}

        {page.type === 'user-message' && (
          <div
            className={classNames(
              'max-w-[820px] w-full',
              'lg:max-w-[clamp(550px,55%,820px)]',
              'xl:max-w-[clamp(600px,60%,820px)]',
              'xxl:max-w-[clamp(600px,70%,820px)]'
            )}
          >
            <div className='max-w-[600px] ml-auto text-right'>
              <div className='inline-block max-w-full py-[10px] px-8 rounded-[30px] bg-accentGradientLight'>
                <span className='font-semibold text-lg leading-[25px] break-words'>
                  {page.items[0].text}
                </span>
              </div>
            </div>
          </div>
        )}

        {page.type === 'form-response' && <ResponseWrapper page={page} />}

        {page.type === 'questions' && <ResponseWrapper page={page} />}
      </div>
    </section>
  );
};

export default SectionContainer;
