export const getRandomInteger = (min, max) => {
	const range = max - min;
	const randomValue = Math.floor(Math.random() * (range + 1)) - range;

	return Math.max(min, Math.min(max, randomValue + min));
};

export const truncateText = (text, maxLength) => {
	if (!text) return '';

	if (text.length <= maxLength) return text;
	return text.slice(0, maxLength) + '...';
};

export const truncateTextWithMarkdown = (text, maxLength) => {
	text = text.replace(/<br\/?>/g, '');

	// If the text is shorter or equal to the max length, return it as is
	if (text.length <= maxLength) {
		return {
			truncatedText: text,
			isTruncated: false,
		};
	}

	let truncatedText = '';
	let currentLength = 0;

	// Split the text into parts, keeping spaces and line breaks intact
	const parts = text.split(/(\s+|\n)/);

	// Loop through the parts of the text and accumulate until the max length is reached
	for (const part of parts) {
		// Stop if the next part would exceed the max length
		if (currentLength + part.length > maxLength) {
			break;
		}

		// Avoid breaking a list item in the middle (e.g., ending with '-')
		if (part === '\n' && truncatedText.trimEnd().endsWith('-')) {
			break;
		}

		// Append the part to the truncated text and update the current length
		truncatedText += part;
		currentLength += part.length;
	}

	// Regex to detect Markdown formatting (e.g., **bold**, *italic*, etc.)
	const markdownRegex = /(\*\*|__|\*|_|`|~~)/g;
	const openTags = [];
	let match;

	// Loop through the text to balance opening and closing Markdown tags
	while ((match = markdownRegex.exec(truncatedText)) !== null) {
		// If the tag is already open, close it; otherwise, open it
		if (openTags.includes(match[0])) {
			// Close tag
			openTags.pop();
		} else {
			// Open tag
			openTags.push(match[0]);
		}
	}

	// If there are unclosed tags, trim the text at the last complete tag
	if (openTags.length > 0) {
		const lastCompleteIndex = truncatedText.lastIndexOf(openTags[0]);
		truncatedText = truncatedText.slice(0, lastCompleteIndex);
	}

	// If the text ends with a newline, remove it to avoid unnecessary blank lines
	if (truncatedText.endsWith('\n')) {
		truncatedText = truncatedText.trimEnd();
	}

	// Regex to detect unfinished list items (e.g., '- ' at the end of the text)
	const unfinishedListItemRegex = /\n\s*-\s*$/;
	if (unfinishedListItemRegex.test(truncatedText)) {
		// Remove the unfinished list item if the text ends with it
		truncatedText = truncatedText.replace(unfinishedListItemRegex, '');
	}

	// Check if the text was actually truncated
	const wasTruncated = truncatedText.length < text.length;

	// Return the truncated text with an ellipsis only if truncated
	return {
		truncatedText: wasTruncated ? truncatedText + '...' : truncatedText,
		isTruncated: wasTruncated,
	};
};
