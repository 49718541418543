import useMediaQuery from '../../hooks/useMediaQuery';

import Popup from './Popup';
import SwipeablePopup from './SwipeablePopup';
import CardPopupContent from '../CardPopupContent';

const CardPopup = ({
	isOpen,
	handleClose,
	isFirst,
	isExpanded,
	isCardPopupOpen,
	isContactSection,
	isProjectsSection,
	contactContent,
	cardContent,
	className,
	zIndex,
}) => {
	const isMobile = useMediaQuery('(max-width: 768px)');

	return isMobile ? (
		<SwipeablePopup isOpen={isOpen} handleClose={handleClose} className={className} zIndex={zIndex}>
			<CardPopupContent
				isFirst={isFirst}
				isExpanded={isExpanded}
				isCardPopupOpen={isCardPopupOpen}
				isProjectsSection={isProjectsSection}
				isContactSection={isContactSection}
				contactContent={contactContent}
				handleClose={handleClose}
				cardContent={cardContent}
			/>
		</SwipeablePopup>
	) : (
		<Popup isOpen={isOpen} handleClose={handleClose} className={className} zIndex={zIndex}>
			<CardPopupContent
				isFirst={isFirst}
				isExpanded={isExpanded}
				isCardPopupOpen={isCardPopupOpen}
				isProjectsSection={isProjectsSection}
				isContactSection={isContactSection}
				contactContent={contactContent}
				handleClose={handleClose}
				cardContent={cardContent}
			/>
		</Popup>
	);
};

export default CardPopup;
