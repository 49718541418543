import { getArticles } from '../../axios/API';
import { classNames } from '../../utils/classNames';
import { useAppContext } from '../../context/AppContextProvider';

import InputField from './InputField';
import RequestRecordPopup from '../popup/RequestRecordPopup';
import menuBlackIcon from '../../assets/request-record/menu-black.svg';
import { useCookies } from 'react-cookie';

const FormComponent = ({ mode, className }) => {
  const {
    handleChangeSectionRefs,
    queryInput,
    setQueryInput,
    setContentList,
    addUserQueryMessage,
    formLoading,
    setFormLoading,
    questionLoading,
    recordingLoading,
    setRecordingLoading,
    isRequestPopupOpen,
    setIsRequestPopupOpen,
    updateRequestRecord,
    isWorkPopupOpen,
    setIsWorkPopupOpen,
    scrollToBottom,
  } = useAppContext();

  const [cookies] = useCookies(['functional-cookies']);

  const onFormSubmit = async (event, formData) => {
    event.preventDefault();

    if (formLoading || questionLoading || (!queryInput && !formData)) {
      return;
    }

    if (isWorkPopupOpen) {
      setIsWorkPopupOpen(false);
    }

    setFormLoading(true);

    const userQuery = formData || queryInput;

    if (cookies['functional-cookies'] === 'allow') {
      updateRequestRecord(userQuery);
    }

    addUserQueryMessage(userQuery);

    const sectionRef = handleChangeSectionRefs(userQuery);

    try {
      setQueryInput('');
      const { data } = await getArticles(userQuery);

      setContentList(prev => [
        ...prev,
        {
          type: 'form-response',
          sectionRef: sectionRef,

          // sectionName: NAVIGATION.find((element) => element.value === value).label,
          // sectionId: NAVIGATION.find((element) => element.value === value).value,

          items: data.block.items || [],
          questions: data.questions || [],

          // nextPage: pageParam + 1,
          // hasMore: availableSectionsList.length > 1,
        },
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setFormLoading(false);
      setTimeout(() => {
        if (sectionRef.current) {
          sectionRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, 100);
    }
  };

  return (
    <form
      onSubmit={onFormSubmit}
      className={classNames('flex flex-col items-center w-full', className)}
    >
      {mode === 'bottom' && (
        <div className='relative max-w-[697px] md:max-w-[709px] w-full lg:hidden flex justify-end'>
          <RequestRecordPopup
            className={[
              'absolute bottom-10 right-5',
              'sm:right-[20px] md:right-[26px]',
              'max-w-[335px] w-full px-4 z-[61]',
            ]}
          />

          <button
            type='button'
            onClick={() => setIsRequestPopupOpen(!isRequestPopupOpen)}
            className={classNames(
              'absolute -right-[3px] md:right-[3px]',
              'w-10 h-10 flex items-center justify-center',
              'self-end border border-[#EAEAEA] rounded-lg',
              'shadow-cardShadow bg-backgroundColor-secondary z-[60]'
            )}
          >
            <img src={menuBlackIcon} alt='menu icon' />
          </button>
        </div>
      )}

      <InputField
        mode={mode}
        value={queryInput}
        onChange={setQueryInput}
        scrollToBottom={scrollToBottom}
        onSubmit={onFormSubmit}
        formLoading={formLoading}
        recordingLoading={recordingLoading}
        setRecordingLoading={setRecordingLoading}
      />
    </form>
  );
};

export default FormComponent;
