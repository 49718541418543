import { useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { classNames } from '../../utils/classNames';

const SwipeablePopup = ({ isOpen, handleClose, children, className, zIndex }) => {
	const popupContentRef = useRef();
	const touchStartY = useRef(null);
	const touchEndY = useRef(null);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => (document.body.style.overflow = 'auto');
	}, [isOpen]);

	useEffect(() => {
		if (!isOpen) return;

		const handleClickOutside = (event) => {
			if (popupContentRef.current && !popupContentRef.current.contains(event.target)) {
				handleClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [isOpen, handleClose, popupContentRef]);

	const handleTouchStart = (event) => (touchStartY.current = event.touches[0].clientY);
	const handleTouchMove = (event) => (touchEndY.current = event.touches[0].clientY);

	const handleTouchEnd = () => {
		if (touchStartY.current && touchEndY.current) {
			const distance = touchEndY.current - touchStartY.current;

			if (distance > 50) handleClose();
		}

		touchStartY.current = null;
		touchEndY.current = null;
	};

	return (
		<AnimatePresence>
			{isOpen && (
				<>
					<div className={classNames('px-4 z-[61]', className)}>
						<motion.div
							ref={popupContentRef}
							initial={{ y: '100%', opacity: 0.75 }}
							animate={{ y: 0, opacity: 1 }}
							exit={{ y: '100%', opacity: 0.75 }}
							transition={{ duration: 0.6, ease: 'easeInOut' }}
							onTouchStart={handleTouchStart}
							onTouchMove={handleTouchMove}
							onTouchEnd={handleTouchEnd}
							onMouseDown={(event) => event.stopPropagation()}
						>
							{children}
						</motion.div>
					</div>

					<motion.div
						className={classNames(
							'fixed inset-0 bg-black z-[60]',
							isOpen ? 'opacity-[56%] visible' : 'opacity-0 invisible',
							zIndex
						)}
						style={{ marginBottom: 0 }}
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.56 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.3 }}
					/>
				</>
			)}
		</AnimatePresence>
	);
};

export default SwipeablePopup;
